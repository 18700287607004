body {
	background-color: #100e17;
	font-family: "Open Sans", sans-serif;
}

.body h1 {
	font-size: 40px;
	margin: 0px 0px 0px 0px;
	padding: 10px 10px 10px 10px;
	color: whitesmoke;
	font-weight: 400;
}

article p {
	color: whitesmoke;
	margin: 0px 0px 0px 0px;
	padding: 10px 10px 10px 10px;
}

.body {
	height: 100%;
	width: 100%;
	background-color: black;
}

.card_container {
	margin-top: 1pc;
	display: flex;
	flex-wrap: wrap;
	margin: auto;
}

.card {
	display: inline-block;
	justify-content: center;
	margin: auto;
	padding: 5px;
	background-color: #100e17;
	box-shadow: -1rem 0 3rem #000;
	border-radius: 10px;
	transition: 0.4s ease-out;
}
.card:hover {
	transform: translateY(-20px);
	transition: 0.4s ease-out;
}

.card_info {
	display: flex;
	flex-wrap: wrap;
	max-width: 400px;
	height: 190px;
	flex-direction: column;
	margin: auto;
	color: white;
}

.bold {
	font-weight: bolder;
}

a {
	margin: auto;
}

.pushable {
	background: hsl(340deg 100% 32%);
	border-radius: 12px;
	border: none;
	padding: 0;
	cursor: pointer;
	outline-offset: 4px;
}
.front {
	display: block;
	padding: 6px 21px;
	border-radius: 12px;
	font-size: 1.25rem;
	background: hsl(345deg 100% 47%);
	color: white;
	transform: translateY(-6px);
}

.pushable:active .front {
	transform: translateY(-2px);
}
